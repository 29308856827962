import React from 'react';
import PropTypes from 'prop-types';

export default function Check({ width, height, color, title}) {
	return (
		<svg height={height} width={width} viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="none">
        <path d="M6 10.5a1 1 0 0 1-.707-.293L.586 5.5 2 4.086l4 4 8-8L15.414 1.5l-8.707 8.707A1 1 0 0 1 6 10.5z" fill={color}/>
      </g>
    </svg>
	);
};

Check.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

Check.defaultProps = {
  height: '11',
  width: '16',
  color: '#0555FF',
  title: "Polls"
};
