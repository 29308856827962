import React from 'react';
import PropTypes from 'prop-types';

export default function FrenchFlagFlat({
  color, width, height, title,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 66 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3.33708C0 2.009 1.07662 0.932373 2.40471 0.932373H22V5.74179H2.40471C1.07663 5.74179 0 4.66517 0 3.33708Z" fill="#002496"/>
    <rect x="22" y="0.932373" width="22" height="4.80942" fill="white"/>
    <path d="M44 0.932373H63.5953C64.9234 0.932373 66 2.009 66 3.33708C66 4.66517 64.9234 5.74179 63.5953 5.74179H44V0.932373Z" fill="#ED2839"/>
    </svg>
  );
}

FrenchFlagFlat.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

FrenchFlagFlat.defaultProps = {
  color: 'white',
  height: '6',
  width: '66',
  title: 'french-flag',
};
