import React from 'react';
import { navigate } from 'gatsby';
import ArrowRight from '../../assets/SVG/ArrowRight';
import Check from '../../assets/SVG/Check';
import {
  Container,
  Title,
  SubTitle,
  CTAContainer,
  PremiumBlock,
  FreemiumBlock,
  Left,
  PriceContainer,
  Number,
  CheckboxContainer,
  CheckItem,
} from './StyledElements';

const PricingFrenchies = function () {
  const handleClickButton = async (link, title) => {
    if (window?.analytics) {
      await window.analytics.track('Landing_1 CTA', {
        buttonTitle: title.toLowerCase(),
        block: 'home pricing',
        page: 'home',
      });
    }
    if (link) navigate(link);
  };

  return (
    <Container>
      <Title>Vous cherchez une alternative moins chère à Typeform ?</Title>
      <SubTitle>Nos prix sont doux comme des agneaux.</SubTitle>
      <CTAContainer onClick={() => { handleClickButton('https://platform.evoltapp.com/signup', 'Essayez gratuitement'); }}>
        <p>Essayez gratuitement</p>
        <ArrowRight />
      </CTAContainer>
      <PremiumBlock>
        <Left>
          <h3>Premium</h3>
          <p>On veut vous faciliter la tâche, plus de complexité, un prix simple sans aucune restriction et surtout sans aucun frais caché. Commencez sans renseigner votre carte bancaire.</p>
          <CheckboxContainer>
            <CheckItem>
              <Check color="white" />
              <p>Réponses illimités</p>
            </CheckItem>
            <CheckItem>
              <Check color="white" />
              <p>Formulaires illimités</p>
            </CheckItem>
            <CheckItem>
              <Check color="white" />
              <p>Intégration à vos outils</p>
            </CheckItem>
          </CheckboxContainer>
        </Left>
        <PriceContainer>
          <Number>24€</Number>
          <p>/mois</p>
        </PriceContainer>
      </PremiumBlock>
      <FreemiumBlock>
        <Left>
          <h3>Gratuit</h3>
          <p>Le formulaire gratuit, ce n’est pas juste un accueil avec l’impossibilité de cliquer sur un bouton ou d’effectuer une action. C’est vraiment un outil avec lequel vous pouvez déjà recueillir des retours tout en traitant la donnée.</p>
          <CheckboxContainer>
            <CheckItem>
              <Check color="white" />
              <p>20 réponses / formulaire</p>
            </CheckItem>
            <CheckItem>
              <Check color="white" />
              <p>2 formulaires</p>
            </CheckItem>
          </CheckboxContainer>
        </Left>
        <PriceContainer>
          <Number>0€</Number>
        </PriceContainer>
      </FreemiumBlock>
    </Container>
  );
};

export default PricingFrenchies;
