import React, { useState, useEffect } from 'react';
import colors from '../../styles/colors';
import Animation from '../../UIComponents/Animation';
import {
  Question, Container, Content, Title, ButtonsContainer, Button, ImageCreate,
} from './StyledElements';

const list = [
  {
    title: 'Sur votre site',
    color: colors.texts.green,
    backgroundColor: colors.blocks.carousselLightGreen,
    buttonColor: colors.texts.green,
    image: 'https://evolt-website.imgix.net/frenchies/mockup-website.png?dpr=2',
  },
  {
    title: 'En conférence',
    color: colors.texts.blue,
    backgroundColor: colors.blocks.carousselLightBlue,
    buttonColor: colors.texts.blue,
    image: 'https://evolt-website.imgix.net/frenchies/mockup-live.png?dpr=2',
  },
  {
    title: 'Dans un email',
    color: colors.texts.yellow,
    backgroundColor: colors.blocks.carousselLightYellow,
    buttonColor: colors.texts.yellow,
    image: 'https://evolt-website.imgix.net/frenchies/mockup-email.png?dpr=2',
  },
  {
    title: 'Via un QR Code',
    color: colors.texts.purple,
    backgroundColor: colors.blocks.carousselLightPurple,
    buttonColor: colors.texts.purple,
    image: 'https://evolt-website.imgix.net/frenchies/mockup-link.png?dpr=2',
  },
];

const Caroussel = function () {
  const [index, setIndex] = useState(0);
  const item = list[index];

  useEffect(() => {
    const timer = setTimeout(() => (
      setIndex(index < 3 ? index + 1 : 0)
    ), 6000);
    return () => {
      clearTimeout(timer);
    };
  }, [index]);

  return (
    <Container backgroundColor={item.backgroundColor}>
      <Content>
        <Question>COMMENT AVOIR PLUS DE RÉPONSES ?</Question>
        <Title color={item.color}>
          Posez la
          <span> bonne question</span>
          {' '}
          au bon
          <span> moment</span>
          {' '}
          dans le bon
          <span> format</span>
          .
        </Title>
        <ButtonsContainer>
          {list.map(({ buttonColor, color, title }, i) => (
            <Button
              onClick={() => setIndex(i)}
              key={i}
              buttonColor={i === index ? buttonColor : 'white'}
              color={i === index ? 'white' : colors.texts.grey}
            >
              <p>{title}</p>
            </Button>
          ))}
        </ButtonsContainer>
        <Animation>
          <ImageCreate
            disableSrcSet
            width={1000}
            height={640}
            // htmlAttributes={{ alt: localize('Process the answers to a form') }}
            src={item.image}
          />
        </Animation>
      </Content>
    </Container>
  );
};

export default Caroussel;
