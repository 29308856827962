import React from 'react';
import {
  Block,
  Content,
  LeftBox,
  RightBox,
  Title,
  Text,
  ImageCreate,
} from './StyledElements';
import Animation from '../../UIComponents/Animation';
import { localize } from '../../components/language/config';

const FormsFinishExcel = function ({
  title,
  text,
}) {
  return (
    <Animation>
      <Block>
        <Content>
          <LeftBox>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </LeftBox>
          <RightBox>
            <ImageCreate
              width={576}
              height={315}
              disableSrcSet
              htmlAttributes={{ alt: localize('Process the answers to a form') }}
              src="https://evolt-website.imgix.net/frenchies/rapport-questionnaire-deschamps.png?dpr=2"
            />
          </RightBox>
        </Content>
      </Block>
    </Animation>
  );
};

export default FormsFinishExcel;
