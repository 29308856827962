import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Animation = function ({ children, delay, style }) {
  const [isDesktop, setDesktop] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 768);
    }
  }, []);

  if (isDesktop) {
    return (
      <div
        data-sal="slide-up"
        data-sal-delay={delay}
        data-sal-duration="500"
        data-sal-easing="ease"
        threshold={0}
      >
        {children}
      </div>
    );
  }
  return (
    <div style={style}>
      { children }
    </div>
  );
};

Animation.propTypes = {
  children: PropTypes.element.isRequired,
  delay: PropTypes.string,
  style: PropTypes.object,
};

Animation.defaultProps = {
  delay: '0',
  style: {},
};

export default Animation;
