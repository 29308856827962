import React from 'react';
import Axeptio from '../../assets/SVG/Axeptio';
import Bricks from '../../assets/SVG/Bricks';
import Credit from '../../assets/SVG/Credit';
// import FasterClass from '../../assets/SVG/FasterClass';
import FrenchFlagFlat from '../../assets/SVG/FrenchFlagFlat';
// import Lono from '../../assets/SVG/Lonos';
import RoyalCanin from '../../assets/SVG/RoyalCanin';
import SncfReseau from '../../assets/SVG/SncfReseau';
import { localize } from '../../components/language/config';
import {
  Content, Text, Title, RightBox, Container, LeftBox, CustomRow, CustomRowBox, ImageCreate, Subtitle,
} from './StyledElement';

const French = function ({ title, text }) {
  return (
    <Container>
      <Content>
        <LeftBox>
          <FrenchFlagFlat />
          <Title>{title}</Title>
          <Text>{text}</Text>
          <Subtitle>Ils nous font confiance</Subtitle>
          <CustomRow>
            <CustomRowBox>
              <Bricks width={111} />
            </CustomRowBox>
            <CustomRowBox>
              <Axeptio width={78} />
            </CustomRowBox>
            <CustomRowBox>
              <Credit width={37} />
            </CustomRowBox>
            <CustomRowBox>
              <RoyalCanin width={70} />
            </CustomRowBox>
            <CustomRowBox>
              <SncfReseau width={41} />
            </CustomRowBox>
          </CustomRow>
        </LeftBox>
        <RightBox>
          <ImageCreate
            disableSrcSet
            width={830}
            height={234}
            htmlAttributes={{ alt: localize('Process the answers to a form') }}
            src="https://evolt-website.imgix.net/feedback/frenchStyle.png?dpr=2"
          />
        </RightBox>
      </Content>
    </Container>
  );
};

export default French;
