import React from 'react';
import {
  Block,
  Content,
} from './StyledElements';
import ProfileBlock from '../ProfileBlock';
import { localize } from '../../components/language/config';

const FeedbackBlock = function () {
  return (
    <Block>
      <Content>
        <ProfileBlock
          title={localize('“Less than a minute”')}
          company="@Bricks"
          name="Roman Juilliot"
          job="Head of growth"
          txt={localize("“When doing tests, what you are looking for is efficiency, and évolt is all about that. It's a real time saver to export the survey report in Powerpoint to present it to my colleagues.”")}
          srcImg="https://evolt-website.imgix.net/feedback/Roman-Juilliot.png?dpr=2&q=100"
        />
        <ProfileBlock
          title={localize('“So Simple”')}
          company="@FasterClass"
          name="Amory Pané"
          job={localize('Co-Founder')}
          txt={localize('“With évolt, I can share different concepts and get feedback quickly. The feedback app is so simple that I can design a survey and get an excellent visual report in minutes.”')}
          srcImg="https://evolt-website.imgix.net/feedback/Amory-Pane.png?dpr=2&q=100"
        />
        <ProfileBlock
          title={localize('“Hyper committed”')}
          company="@Lono"
          name="Jerôme Barthes"
          job={localize('Co-Founder')}
          txt={localize('“We used to manipulate sophisticated survey tools. It is now so easy to use évolt. I can design a survey in a minute to get user feedback.”')}
          srcImg="https://evolt-website.imgix.net/feedback/Jerome-Barthez.png?dpr=2&q=100"
        />
      </Content>
    </Block>
  );
};

export default FeedbackBlock;
