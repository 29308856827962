import colors from '../../styles/colors';
import styled from 'styled-components';
import Imgix from 'react-imgix';

export const Container  = styled.div`
  width: 100%;
  height: auto;
  padding: 60px 20px;
  box-sizing: border-box;
  background: ${({ backgroundColor }) => backgroundColor};
`;

export const Content = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
`;

export const Title = styled.h2`
  margin-top: 12px;
  font-size: 32px;
  line-height: 36px;
  max-width: 600px;
  color: ${colors.texts.black};
  & > span {
    color: ${({ color }) => color};
  }
  @media screen and (max-width: 950px) {
    max-width: 100%;
  }
`;

export const Question = styled.p`
  font-size: 16px;
  max-width: 600px;
  font-weight: 600;
  color: ${colors.texts.black};
  @media screen and (max-width: 950px) {
    max-width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: 60px 0 40px 0;
  gap: 30px;
  @media screen and (max-width: 950px) {
    gap: 20px 10px;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background:  ${({ buttonColor }) => buttonColor};
  color: ${({ color }) => color};
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  height: 45px;
  border-radius: 100px;
  font-size: 16px;
`;

export const ImageCreate = styled(Imgix)`
  max-width: 1000px;
  width: 100%;
  height: auto;
`;
