import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Typewriter from 'typewriter-effect';
import {
  Block,
  Content,
  Title,
  BodyTxt,
  ImageCreate,
} from './StyledElements';
import Button from '../../UIComponents/Button';
import { localize } from '../../components/language/config';
import Animation from '../../UIComponents/Animation';

// eslint-disable-next-line func-names
const HomeHeader = function ({
  textIn,
}) {
  const handleClickButton = async (link, title) => {
    if (window?.analytics) {
      await window.analytics.track('Landing_1 CTA', {
        buttonTitle: title.toLowerCase(),
        block: 'home header',
        page: 'home',
      });
    }
    if (link) navigate(link);
  };

  return (
    <Block>
      <Content>
        <Title>
          <Typewriter
            options={{ loop: false }}
            onInit={(typewriter) => {
              typewriter
                .typeString('Le Typeform français !')
                .pauseFor(1000)
                .deleteChars(19)
                .typeString('form. français !')
                .start();
            }}
          />
        </Title>
        <BodyTxt>{textIn}</BodyTxt>
        <Button label="Commencez gratuitement" onClick={() => handleClickButton('https://platform.evoltapp.com/signup', 'Commencer maintenant')} />
        <Animation style={{ width: '100%' }}>
          <ImageCreate
            disableSrcSet
            width={1000}
            height={476}
             // htmlAttributes={{ alt: localize('Process the answers to a form') }}
            src="https://evolt-website.imgix.net/frenchies/formulaire.png?dpr=2"
          />
        </Animation>
      </Content>
    </Block>
  );
};

HomeHeader.propTypes = {
  textIn: PropTypes.string.isRequired,
};

HomeHeader.defaultProps = {
};

export default HomeHeader;
