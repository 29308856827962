import React from 'react';
import {
  AlignBox, Container, ImageCreate, Text, Title,
} from './StyledElements';

const Integration = function ({ title, text }) {
  return (
    <Container>
      {/* <Content> */}
      <ImageCreate
        width={595}
        height={588}
        disableSrcSet
        htmlAttributes={{ alt: 'automatisez des taches avec zappier, hubspot ...' }}
        src="https://evolt-website.imgix.net/frenchies/automatisation-questionnaire.png?dpr=2"
      />
      <AlignBox>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </AlignBox>
    </Container>
  );
};

export default Integration;
