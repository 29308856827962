import Imgix from 'react-imgix';
import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  margin-top: 100px;
  width: 100%;
  height: auto;
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  background: ${colors.blocks.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  background: red;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  margin: 0 auto;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  margin-top: 12px;
  font-size: 28px;
  line-height: 36px;
  max-width: 400px;
  margin-bottom: 20px;
  text-align: center;
  color: ${colors.titles.black};
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  max-width: 400px;
  text-align: center;
  color: ${colors.texts.grey};
`;

export const ImageCreate = styled(Imgix)`
  max-width: 595px;
  width: 100%;
  height: auto;
`;

export const AlignBox = styled.div`
  padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 15px;

  @media screen and (max-width: 768px) {
    bottom: 0px;
  }

  @media screen and (max-width: 500px) {
    margin-top: -130px;
    position: relative;
  }
`;
