import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 100px 20px;
  background: ${colors.blocks.darkBlue};
  display: flex;
  margin-top: 100px;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  max-width: 500px;
  color: white;
  text-align: center;
`;

export const SubTitle = styled.p`
  color: white;
  opacity: 0.7;
  margin-top: 20px;
`;

export const CTAContainer = styled.div`
  gap: 10px;  
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  p {
    color: ${colors.texts.blue};
  }
`;

export const PremiumBlock = styled.div`
  position: relative;
  max-width: 1000px;
  width: 100%;
  padding: 40px 50px;
  background: #0555FF;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 58px;
  display: flex;
  justify-content: space-between;
  background-image: url('https://evolt-website.imgix.net/frenchies/background-image-premium.png?dpr=2');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media(max-width: 580px) {
    padding: 20px;
  }
`;

export const Left = styled.div`
  color: white;
  display: flex;
  flex-direction: column;

  h3 {
    color: white;
    margin-bottom: 10px;
    @media(max-width: 580px) {
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 16px;
    max-width: 568px;
  }
`;

export const CheckboxContainer = styled.div`
  gap: 30px;
  display: flex;
  margin-top: 20px;
  align-items: center;

  @media(max-width: 580px) {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CheckItem = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const FreemiumBlock = styled.div`
  position: relative;
  max-width: 1000px;
  width: 100%;
  padding: 40px 50px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90.17deg, rgba(255, 255, 255, 0.1) 0.13%, rgba(255, 255, 255, 0) 99.86%);

  @media(max-width: 580px) {
    padding: 20px;
  }
`;

export const PriceContainer = styled.div`
  top: 40px;
  right: 40px;
  position: absolute;
  display: flex;
  align-items: baseline;
  color: white;
  gap: 4px;

  @media(max-width: 580px) {
    top: 20px;
    right: 20px;
  }
`;

export const Number = styled.h3`
  font-family: Gustavo-Bold;
  font-size: 40px;
  color: white;
`;
