import React from 'react';
import PropTypes from 'prop-types';

export default function Bricks({
  width, height, color, title,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 164 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path d="M0 1.41964C0 0.9716 0.363137 0.608398 0.811088 0.608398H7.77674C8.22468 0.608398 8.58783 0.9716 8.58783 1.41964V11.7114C8.58783 12.1595 8.22469 12.5227 7.77674 12.5227H0.811089C0.363138 12.5227 0 12.1595 0 11.7114V1.41964Z" fill={color} />
      <path d="M11.0811 9.17794C11.0811 8.72991 11.4442 8.3667 11.8921 8.3667H18.8578C19.3057 8.3667 19.6688 8.72991 19.6688 9.17794V19.4698C19.6688 19.9178 19.3057 20.2809 18.8578 20.2809H11.8921C11.4442 20.2809 11.0811 19.9178 11.0811 19.4698V9.17794Z" fill={color} />
      <path d="M11.0811 1.41964C11.0811 0.9716 11.4442 0.608398 11.8921 0.608398H18.8578C19.3057 0.608398 19.6688 0.9716 19.6688 1.41964V5.06161C19.6688 5.50965 19.3057 5.87284 18.8578 5.87284H11.8921C11.4442 5.87284 11.0811 5.50965 11.0811 5.06161V1.41964Z" fill={color} />
      <path d="M0 15.8276C0 15.3795 0.363137 15.0164 0.811088 15.0164H7.77674C8.22468 15.0164 8.58783 15.3795 8.58783 15.8276V19.4697C8.58783 19.9176 8.22469 20.2808 7.77674 20.2808H0.811089C0.363138 20.2808 0 19.9176 0 19.4697V15.8276Z" fill={color} />
      <path d="M42.58 10.0221C43.7317 10.3869 44.634 11.0013 45.2866 11.8652C45.9393 12.71 46.2656 13.7563 46.2656 15.0044C46.2656 16.7706 45.5746 18.1338 44.1925 19.0937C42.8296 20.0345 40.8331 20.5049 38.2033 20.5049H27.78V0.345703H37.6275C40.0845 0.345703 41.9658 0.816085 43.2711 1.75685C44.5955 2.69761 45.2578 3.97436 45.2578 5.58709C45.2578 6.56624 45.0179 7.4398 44.5379 8.20777C44.0773 8.97574 43.4246 9.58052 42.58 10.0221ZM32.4158 3.85916V8.61096H37.0517C38.2033 8.61096 39.0767 8.40937 39.6719 8.00619C40.2669 7.603 40.5644 7.00782 40.5644 6.22066C40.5644 5.43349 40.2669 4.84792 39.6719 4.46393C39.0767 4.06074 38.2033 3.85916 37.0517 3.85916H32.4158ZM37.8579 16.9914C39.0863 16.9914 40.0077 16.7899 40.622 16.3867C41.2555 15.9835 41.5722 15.3595 41.5722 14.5147C41.5722 12.8444 40.334 12.0092 37.8579 12.0092H32.4158V16.9914H37.8579Z" fill={color} />
      <path d="M62.6391 20.5049L58.7519 14.8891H54.4617V20.5049H49.7971V0.345703H58.5215C60.3068 0.345703 61.852 0.643292 63.1573 1.23847C64.4818 1.83364 65.4993 2.67841 66.2094 3.77276C66.9196 4.86712 67.2748 6.16305 67.2748 7.6606C67.2748 9.15814 66.9101 10.4541 66.1807 11.5484C65.4704 12.6236 64.4531 13.4492 63.1285 14.0251L67.6491 20.5049H62.6391ZM62.5526 7.6606C62.5526 6.52785 62.1879 5.66388 61.4586 5.06871C60.7291 4.45434 59.6637 4.14715 58.2624 4.14715H54.4617V11.174H58.2624C59.6637 11.174 60.7291 10.8669 61.4586 10.2525C62.1879 9.63812 62.5526 8.77416 62.5526 7.6606Z" fill={color} />
      <path d="M70.9705 0.345703H75.635V20.5049H70.9705V0.345703Z" fill={color} />
      <path d="M90.0643 20.8504C88.0104 20.8504 86.1484 20.4087 84.4785 19.5255C82.8276 18.6232 81.5223 17.3848 80.5624 15.8105C79.6218 14.217 79.1516 12.4219 79.1516 10.4252C79.1516 8.42844 79.6218 6.64292 80.5624 5.06859C81.5223 3.47505 82.8276 2.23671 84.4785 1.35354C86.1484 0.451181 88.0201 0 90.0932 0C91.84 0 93.414 0.307187 94.8153 0.921561C96.2358 1.53594 97.4259 2.41909 98.3857 3.57105L95.3911 6.33574C94.0282 4.7614 92.339 3.97424 90.3236 3.97424C89.0758 3.97424 87.9625 4.25262 86.9835 4.80939C86.0045 5.34698 85.2367 6.10534 84.68 7.08451C84.1425 8.06366 83.8738 9.17721 83.8738 10.4252C83.8738 11.6731 84.1425 12.7867 84.68 13.7658C85.2367 14.745 86.0045 15.513 86.9835 16.0697C87.9625 16.6073 89.0758 16.8761 90.3236 16.8761C92.339 16.8761 94.0282 16.0793 95.3911 14.4858L98.3857 17.2505C97.4259 18.4216 96.2358 19.3144 94.8153 19.9287C93.3948 20.5431 91.8112 20.8504 90.0643 20.8504Z" fill={color} />
      <path d="M108.878 12.5852L106.171 15.4074V20.5049H101.536V0.345703H106.171V9.76291L115.098 0.345703H120.28L111.93 9.33093L120.77 20.5049H115.328L108.878 12.5852Z" fill={color} />
      <path d="M129.05 20.8504C127.457 20.8504 125.912 20.6392 124.415 20.2167C122.936 19.7752 121.746 19.2088 120.844 18.5177L122.428 15.0042C123.292 15.6378 124.319 16.1465 125.509 16.5305C126.699 16.9144 127.889 17.1065 129.079 17.1065C130.404 17.1065 131.382 16.9144 132.016 16.5305C132.65 16.1273 132.966 15.5993 132.966 14.9466C132.966 14.4666 132.774 14.073 132.39 13.7658C132.025 13.4395 131.546 13.1803 130.951 12.9883C130.375 12.7963 129.588 12.5851 128.59 12.3547C127.054 11.9899 125.797 11.6251 124.818 11.2603C123.839 10.8955 122.994 10.31 122.284 9.5036C121.593 8.69724 121.247 7.62208 121.247 6.27813C121.247 5.10699 121.564 4.05103 122.197 3.11027C122.831 2.15031 123.781 1.39195 125.048 0.835165C126.334 0.278388 127.899 0 129.741 0C131.028 0 132.285 0.153593 133.513 0.460779C134.742 0.767967 135.817 1.20955 136.738 1.78552L135.299 5.32778C133.437 4.27182 131.575 3.74384 129.713 3.74384C128.407 3.74384 127.438 3.95503 126.805 4.37741C126.19 4.7998 125.883 5.35658 125.883 6.04775C125.883 6.73891 126.238 7.2573 126.948 7.60288C127.678 7.92927 128.782 8.25565 130.26 8.58204C131.796 8.94683 133.053 9.31161 134.031 9.67639C135.01 10.0412 135.846 10.6172 136.537 11.4043C137.247 12.1915 137.603 13.257 137.603 14.601C137.603 15.7529 137.276 16.8089 136.622 17.7689C135.99 18.7096 135.03 19.4583 133.744 20.0151C132.458 20.572 130.893 20.8504 129.05 20.8504Z" fill={color} />
      <path d="M140.8 21.0001C140.455 21.0001 140.16 20.8831 139.914 20.6494C139.668 20.4034 139.546 20.0958 139.546 19.7267C139.546 19.3698 139.668 19.0745 139.914 18.8408C140.16 18.5947 140.455 18.4717 140.8 18.4717C141.145 18.4717 141.434 18.5885 141.667 18.8224C141.901 19.0561 142.017 19.3575 142.017 19.7267C142.017 20.0958 141.894 20.4034 141.649 20.6494C141.415 20.8831 141.132 21.0001 140.8 21.0001Z" fill={color} />
      <path d="M148.602 21.0001C147.607 21.0001 146.714 20.7848 145.927 20.3542C145.152 19.9236 144.543 19.333 144.1 18.5825C143.658 17.8196 143.436 16.9584 143.436 15.9987C143.436 15.039 143.658 14.1839 144.1 13.4333C144.543 12.6705 145.152 12.0799 145.927 11.6616C146.714 11.2309 147.607 11.0156 148.602 11.0156C149.488 11.0156 150.275 11.194 150.964 11.5508C151.666 11.9076 152.207 12.4244 152.588 13.1011L151.241 13.9685C150.934 13.501 150.552 13.1503 150.097 12.9165C149.642 12.6828 149.138 12.5659 148.584 12.5659C147.944 12.5659 147.367 12.7074 146.85 12.9904C146.344 13.2734 145.946 13.6794 145.65 14.2084C145.367 14.7252 145.225 15.322 145.225 15.9987C145.225 16.6876 145.367 17.2966 145.65 17.8257C145.946 18.3425 146.344 18.7423 146.85 19.0253C147.367 19.3083 147.944 19.4498 148.584 19.4498C149.138 19.4498 149.642 19.333 150.097 19.0992C150.552 18.8654 150.934 18.5147 151.241 18.0472L152.588 18.8962C152.207 19.5729 151.666 20.0958 150.964 20.4649C150.275 20.8217 149.488 21.0001 148.602 21.0001Z" fill={color} />
      <path d="M158.926 21.0001C157.954 21.0001 157.08 20.7848 156.305 20.3542C155.531 19.9236 154.922 19.333 154.479 18.5825C154.048 17.8196 153.833 16.9584 153.833 15.9987C153.833 15.039 154.048 14.1839 154.479 13.4333C154.922 12.6705 155.531 12.0799 156.305 11.6616C157.08 11.2309 157.954 11.0156 158.926 11.0156C159.897 11.0156 160.765 11.2309 161.528 11.6616C162.302 12.0799 162.906 12.6705 163.336 13.4333C163.778 14.1839 164 15.039 164 15.9987C164 16.9584 163.778 17.8196 163.336 18.5825C162.906 19.333 162.302 19.9236 161.528 20.3542C160.765 20.7848 159.897 21.0001 158.926 21.0001ZM158.926 19.4498C159.554 19.4498 160.112 19.3083 160.604 19.0253C161.11 18.7301 161.503 18.324 161.787 17.8073C162.069 17.2782 162.21 16.6754 162.21 15.9987C162.21 15.322 162.069 14.7252 161.787 14.2084C161.503 13.6794 161.11 13.2734 160.604 12.9904C160.112 12.7074 159.554 12.5659 158.926 12.5659C158.298 12.5659 157.733 12.7074 157.229 12.9904C156.737 13.2734 156.343 13.6794 156.048 14.2084C155.765 14.7252 155.623 15.322 155.623 15.9987C155.623 16.6754 155.765 17.2782 156.048 17.8073C156.343 18.324 156.737 18.7301 157.229 19.0253C157.733 19.3083 158.298 19.4498 158.926 19.4498Z" fill={color} />
    </svg>
  );
}

Bricks.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
};

Bricks.defaultProps = {
  height: '21',
  width: '164',
  color: '#ffffff',
};