import styled, { keyframes } from 'styled-components';
import Imgix from 'react-imgix';
import colors from '../../styles/colors';

export const Block = styled.div`
  width: 100%;
  padding: 20px 36px 0px;
  box-sizing: border-box;

  @media screen and (max-width: 1080px) {
    padding: 20px 20px 100px 20px;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const Title = styled.h1`
  width: 100%;
  font-size: 40px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  #typeForm {
    color: pink !important;
  }
  .Typewriter {
    text-align: center;
  }
  .Typewriter__cursor {
    display: none;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

`;

export const BodyTxt = styled.p`
  color: ${colors.texts.grey};
  font-family: Euclid-Circular;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 362px;

  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;


export const AccessCodeContainer = styled.div`
  padding: 10px 20px;
  max-width: 524px;
  width: 100%;
  background: ${colors.texts.black};
  border-radius: 60px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center; 
  margin-bottom: 80px;

  p {
    font-size: 15px;
    color: white;
  }

  @media(max-width: 590px) {
    border-radius: 8px;
    flex-direction: column;
    p {
      margin-bottom: 10px;
    }
  }
`;

export const ArrowContainer = styled.div`
  margin-top: -18.5px;
  opacity: ${(props) => (props.display ? '1' : '0')};
  cursor: pointer;
  width: 35px;
  transform: rotate(-90deg);
  transition: 0.3s;

  &:active { opacity: 0.4; transform: rotate(-90deg) scaleX(1.2) scaleY(1.2);}
`;

export const InputCodeContainer = styled.div`
  width: 270px;
  height: 42px;
  padding: 0px 15px;
  background: white;
  border-radius: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputCode = styled.input`
  width: 100%;
  border-radius: 60px;
  border: none;
  font-size: 14px;

  @media(max-width: 590px) {
    width: 100%;
    border-radius: 8px;
  }

  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.texts.placeholder};
  }
  :-ms-input-placeholder {
     color: ${colors.texts.placeholder};
  }
`;

export const ImageCreate = styled(Imgix)`
  max-width: 1000px;
  width: 100%;
  height: auto;
  margin-top: 60px;
  margin-bottom: 80px;
`;
