import React from 'react';
import PropTypes from 'prop-types';
import {
  Block,
  ImgFaces,
  Name,
  Job,
  FeedbackText,
  FeedbackTitle,
  Company,
  ProfilCard,
  ProfilCardTxtBox,
} from './StyledElements';

const ProfileBlock = function ({
  name, job, title, txt, srcImg, company,
}) {
  return (
    <Block>
      <div>
        <FeedbackTitle>{title}</FeedbackTitle>
        <FeedbackText>{txt}</FeedbackText>
      </div>
      <ProfilCard>
        <ImgFaces width={80} height={80} disableSrcSet htmlAttributes={{ alt: 'Feedback' }} src={srcImg} />
        <ProfilCardTxtBox>
          <Name>{name}</Name>
          <Job>{job}</Job>
          <Company>{company}</Company>
        </ProfilCardTxtBox>
      </ProfilCard>
    </Block>
  );
};

ProfileBlock.propTypes = {
  name: PropTypes.string,
  job: PropTypes.string,
  txt: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  srcImg: PropTypes.string,
};

ProfileBlock.defaultProps = {
};

export default ProfileBlock;
