import styled from 'styled-components';
import Imgix from 'react-imgix';
import colors from '../../styles/colors';

export const Block = styled.blockquote`
  border: 1px solid ${colors.borders.lightGrey};
  border-radius: 18px;
  box-shadow: 0px 2px 4px rgba(41, 41, 43, 0.05);
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 24px;
  transition: 0.4s;

  &:hover {
    box-shadow: 0px 10px 20px rgba(41, 41, 43, 0.15);
  }
`;

export const FeedbackTitle = styled.p`
  font-family: Gustavo-Bold;
  font-size: 32px;
  line-height: 38px;
  color: ${colors.titles.darkBlue};
  margin-bottom: 28px;
`;

export const FeedbackText = styled.p`
  font-size: 16px;
  line-height: 27px;
  color: ${colors.texts.grey};
  margin-bottom: 24px;
`;

export const ImgFaces = styled(Imgix)`
  width: 52px;
  height: 52px;
`;

export const Name = styled.cite`
  font-family: Euclid-Circular;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: 700 !important;
  color: ${colors.titles.black};
`;

export const Job = styled.p`
  font-family: Gustavo-Regular;
  font-size: 15px;
  line-height: 22px;
  color: ${colors.texts.blue};
`;

export const Company = styled.p`
  font-family: Gustavo-Regular;
  font-size: 15px;
  line-height: 22px;
  color: ${colors.texts.salmon};
`;

export const ProfilCard = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProfilCardTxtBox = styled.div`
  margin-left: 12px;
`;
