import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowRight({
  width, height, title,
}) {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7867 11.605L14.1817 6L13.1534 7.02835L17.5169 11.3919H4.72726C4.32509 11.3919 4 11.7178 4 12.1192C4 12.5207 4.32509 12.8465 4.72726 12.8465H17.5169L13.1534 17.2101L14.1817 18.2384L19.7867 12.6334C20.0711 12.349 20.0711 11.8894 19.7867 11.605Z" fill="#0555FF" />
    </svg>
  );
}

ArrowRight.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

ArrowRight.defaultProps = {
  height: '24',
  width: '24',
  title: 'IconFeedback',
};
