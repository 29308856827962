import React from 'react';
import HomeHeader from '../Blocks/HomeHeader';
import FeedbackBlock from '../Blocks/FeedbackBlock';
import Layout from '../components/Layout';
import { WithIntl } from '../components/language/withIntl';
import HeaderHtml from '../components/HeaderHtml';
import FormsFinishExcel from '../Blocks/FormsFinishExcel';
import French from '../Blocks/French';
import Integration from '../Blocks/Intergration';
import Caroussel from '../Blocks/Caroussel';
import useScrollPercentage from '../utils/useScrollPercentage';
import PricingFrenchies from '../Blocks/PricingFrenchies';

// eslint-disable-next-line func-names
const IndexPage = function () {
  const [showWidget, setShowWidget] = React.useState(false);
  const [scrollRef, scrollPercentage] = useScrollPercentage();

  React.useEffect(() => {
    if (!showWidget && scrollPercentage >= 98) {
      if (window?.analytics) { window.analytics.track('Landing_1 Widget_Loaded'); }
      setShowWidget(true);
    }
  }, [showWidget, scrollPercentage]);

  return (
    <div style={{ height: '100vh', overflowY: 'scroll' }} ref={scrollRef}>
      <Layout>
        <HeaderHtml
          language="fr"
          title="Evolt - Créez un fomulaire engageant"
          description="Evolt est une SaaS français, permettant de créer des formulaires engagneants et de traitez vos réponses en un instant."
          showWidget={showWidget}
        />
        <HomeHeader
          textIn="Créez un formulaire engageant. Obtenez plus de résultats et traitez les en deux clics."
        />
        <Caroussel />
        <French
          title="Les frenchies contrataquent"
          text={'Le premier formulaire français, conforme RGPD.\nAvec évolt toutes vos données sont sécurisées et hébergées en Europe.'}
        />
        <FormsFinishExcel
          title="Finis Excel, gagnez du temps sur le traitement"
          text={'Même Monsieur Déchamps nous utilise pour faire les rapports d’après match !\n\nIntuitif et conçu pour vous faire gagner du temps. Accéder aux informations importantes est un délice de facilité.'}
        />
        <Integration
          title="On s’intègre parfaitement à vos équipes et vos outils"
          text="Envoyez la donnée grâce aux webhooks. Créez des scénarios d’automatisation avec Zapier ou sur vos propres serveurs."
        />
        <PricingFrenchies />
        <FeedbackBlock />
      </Layout>
    </div>
  );
};

export default WithIntl()(IndexPage);
