import styled from 'styled-components';
import Imgix from 'react-imgix';
import colors from '../../styles/colors';

export const Container = styled.div`
   width: 100%;
  height: auto;
  padding: 80px 20px 80px 20px;
  box-sizing: border-box;
  background: ${colors.blocks.darkBlue};
`;

export const Content = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  margin: 0 auto;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 950px) {
    max-width: 100%;
  }
`;

export const RightBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 50%;
  width: 100%;
  @media screen and (max-width: 950px) {
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  margin-top: 12px;
  font-size: 40px;
  line-height: 45px;
  max-width: 325px;
  margin-bottom: 20px;
  color: ${colors.texts.white};
  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-right: 30px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  max-width: 440px;
  color: ${colors.texts.white};
  opacity: 70%;
  white-space: pre-line;

  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-right: 30px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 800;
  font-size: 14px;
  color: ${colors.texts.white};
  margin-top: 36px;
  @media screen and (max-width: 950px) {
  }
`;

export const ImageCreate = styled(Imgix)`
  max-width: 830px;
  width: 100%;
  height: auto;
`;

export const CustomRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const CustomRowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
