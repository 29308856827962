import styled from 'styled-components';
import Imgix from 'react-imgix';
import colors from '../../styles/colors';

export const Block = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  background: #EAF1FF;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 53px 0px 0px 36px;
  gap: 30px;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Title = styled.h2`
  font-size: 32px;
  line-height: 38px;
  max-width: 325px;
  margin-bottom: 20px;
  color: ${colors.titles.black};

  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-right: 30px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  max-width: 357px;
  color: ${colors.texts.grey};
  white-space: pre-line;

  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-right: 30px;
  }
`;

export const ImageCreate = styled(Imgix)`
  max-width: 576px;
  width: 100%;
  height: auto;
`;
